<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Users list"  />
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-secondary" type="button" data-bs-toggle="modal"
                                data-original-title="test" data-bs-target="#userAdd">Add User</button>
                        </div>
                        <div class="card-body badge-spacing">
                            <div class="table-responsive custom-scrollbar">
                                <table class="table display" id="basic-1">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>Username</th>
                                            <th>Name</th>
                                            <th>Contact</th>
                                            <th>Nagarpalika</th> 
                                            <th>Password</th> 
                                            <th>Role</th>
                                            <th>Status</th> 
                                            <th>Action</th>
                                
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  v-for="(item ,index)  in users" :key="item.id" >
                                            <td>{{index+1}}</td>
                                            <td>{{item.empcode}}</td>
                                            <td>{{item.empname}}</td>
                                            <td>{{item.empcontact}}</td>
                                            <td>{{item.np_name}}</td> 
                                            <td>{{item.emppwd}}</td> 
                                            <td>{{roles[item.emprole]}}</td> 
                                            <td>{{(item.empstatus==1)?'Active':'De-Active'}}</td>
                                            <td>
                                                <ul class="action">
                                                    <li class="edit" @click="edit_user(item.id)"><i class="icon-pencil-alt"></i></li>
                                                    <li class="delete" @click="remove_user(item.id)"><i class="icon-trash"></i></li>
                                                </ul>
                                            </td> 
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer   start-->

    <ModalsPart v-if="!['login'].includes($route.name)" :nagarpalika_list="nagarpalika_list" :userDetails="userDetails" @getUserData="updateUserData"/>
</template>

<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import axios from 'axios';
const config = {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: 'json'
         };
export default {
    name: 'users', 
    components: {
        PageTitle, ModalsPart
    }, mounted() {
        this.getBasics();

    }, data() {
        return { 
          users: [],
          roles: ["","System Admin","Nagarpalika Admin","Nagarpalika HOD","Nagarpalika Employee","Nagarpalika Admin"],
          nagarpalika_list:[],
          userDetails:[]
        }
    },
    methods: { 
        updateUserData(value) {
            this.users = value;
        },
        getBasics() {
            var self = this;
            axios.post('getUsers').then(function (response) {
                self.users = response.data.users; 
                self.nagarpalika_list = response.data.nagarpalikas; 
                self.userDetails = response.data.userDetails; 
                window.scrollTo(0, 0);
            });
        },
        remove_user(id) {
            var self = this;
            axios.post('removeUser', {id:id}).then(function (response) {
                self.$swal(response.data.msg);
                
                if(response.data.is==1){
                    self.users = response.data.users; 
                    self.nagarpalika_list = response.data.nagarpalikas; 
                    
                }
                window.scrollTo(0, 0);
            });
        }
       
    }
}
</script>
