<template>

  <!-- Page Sidebar Ends-->
  <div class="page-body">
  
    <PageTitle :title="survey_details.name" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row size-column">

        <div class="col-xxl-9 box-col-12">
                    <div class="card card-body">  
          <div class="row">
           
           
          
                        
                  
                        
            <div class="col-xl-6 col-sm-12 col-12"  v-for="(item ,index)  in ques" :key="item.id"  >
              
                 <label class="form-label"><span class="f-light f-w-500 f-14">{{(1+index)+") "+item.que}}</span></label>
                  
                      <p v-if="item.questiontype==1">
                          <input class="form-control" type="text" v-model="item.ans">
                      </p> 
                      <p v-else-if="item.questiontype==2" >
                          <label :for="item.id_yes" @click="setRadio('Yes', item)"><input type="radio" :id="item.id_yes" name="ans"   /> Yes</label>
                          <label :for="item.id_no"  @click="setRadio('No', item)"><input type="radio" name="ans" :id="item.id_no" /> No</label>
                      </p> 
                      <p v-else-if="item.questiontype==3">
                          <input type="file" /> 
                      </p> 
                      <p v-else-if="item.questiontype==5" >
                          <button class="btn btn-dark" @click="getLocation()">Get Location</button>
                          <input type="hidden" v-model="item.ans">
                      </p>  
                    
              
              </div>
          
            </div>
           <div class="col-12">
                        <button class="btn btn-primary" @click="save_survey">Submit Survey</button>
                    </div>
            </div>
             
          </div>
      </div>
      </div>
    </div>
    <!-- Container-fluid Ends--> 

</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  name: 'StartSurvey',
  components: {
        PageTitle ,Bar
    },
      mounted() {
            if (this.$route.query.q) {
                this.getSurveyQues(this.$route.query.q);
            }else {
                this.getBasics();
            }
        }, 
        data() {
        return { 
          ques: [], 
          userDetails: [],
          survey_details: {name:''}
        }
    },
    methods: {
      save_survey() {
            var self = this;
            axios.post('saveFrontSurvey',{ques:self.ques,survey:self.survey_details}).then(function (response) {
               
               self.$swal(response.data.msg);
               self.surveys  = response.data.surveys ;   
               
                window.scrollTo(0, 0);
            });
        },
        setRadio(what,item){
            item.ans=what;
        },
      getBasics() {
            var self = this;
            axios.post('getFrontDash').then(function (response) {
                self.surveys  = response.data.surveys ;   
                window.scrollTo(0, 0);
            });
        },
      getSurveyQues(servey_id) {
            var self = this;
            axios.post('getFrontSurveyQues',{servey_id:servey_id}).then(function (response) {
                self.ques  = response.data.ques ;   
                self.userDetails  = response.data.userDetails ;   
                self.survey_details  = response.data.survey_details ;   
                window.scrollTo(0, 0);
            });
        },
        getLocation(){
            navigator.geolocation.getCurrentPosition(this.success, this.error);
        },
        
        error(err) {
            console.log(err);
        }, 
        success(position) {
            const latitude  = position.coords.latitude;
            const longitude = position.coords.longitude;

            // Do something with the position
        },

        // This will open permission popup
        
    }
}
</script>
