<template> 
<button class="btn bt-danger" @click="backBtnPress()"><i class="fa fa-arrow-left"></i> Go Back</button>
    <div class="container-fluid">
      <div class="user-profile social-app-profile">
        <div class="row">
          <!-- user profile first-style start-->
          <div class="col-sm-12 box-col-12">
            <div class="card hovercard text-center mb-2"> 
                <video ref="video" autoplay></video>
               
                  <canvas ref="canvas" style="display: none"   v-if="!photo"></canvas> 
                <img :src="photo" alt="Captured photo"   v-if="photo"> 
               
            </div>
          </div>
                <button @click="capturePhoto" class="btn btn-primary">Capture Photo</button>
        </div>
      </div>
    </div> 
</template>

<script>  
export default {
 
  data() {
    return {
      photo: null,
    };
  },
  mounted() {
    this.startWebcam();
  },
  methods: {
    async startWebcam() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: {facingMode: 'environment'  } });
        this.$refs.video.srcObject = stream;
      } catch (error) {
        console.error("Error accessing webcam:", error);
      }
    },
    backBtnPress(){
        this.$emit('backBtn',"") ;
    },
    capturePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

     // this.photo = canvas.toDataURL('image/png');
        this.$emit('setImage',canvas.toDataURL('image/png')) ;
        //this.$router.go(-1);
        
    },
 
    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
  },
};
</script>

<style scoped>
video {
      width: 100vh !important;
      
     height: 480px !important;

}
</style>