<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Nagarpalika"  />
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-secondary" type="button" data-bs-toggle="modal"
                                data-original-title="test" data-bs-target="#nagarpalikaAdd">Add Nagarpalika</button>
                        </div>
                        <div class="card-body badge-spacing">
                            <div class="table-responsive custom-scrollbar">
                                <table class="table display" id="basic-1">
                                    <thead>
                                        <tr> 
                                        <th>Sr.No</th>
                                            <th>Name</th> 
                                            <th>Address</th>
                                            <th>Pincode</th>
                                            <th>Logo</th>
                                            <th>contact</th> 
                                            <th>Status</th>
                                            <th>Action</th> 
                                
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  v-for="(item ,index)  in nagarpalikas" :key="item.id" >
                                             <td>{{index+1}}</td>
                                             <td>{{item.name}}</td>
                                            <td>{{item.address}}</td> 
                                            <td>{{item.pincode}}</td>
                                            <td><img :src="getImageUrl(item.logo)" alt="Image" style="width: auto; height: 100px;" /></td>
                                            <td>{{item.contact}}</td> 
                                            <td>{{(item.status==1)?'Active':'De-Active'}}</td>
                                            <td>
                                                <ul class="action">
                                                    <li class="edit" @click="edit_nagarpalika(item.id)"><i class="icon-pencil-alt"></i></li>
                                                    <li class="delete" @click="remove_nagarpalika(item.id)"><i class="icon-trash"></i></li>
                                                </ul>
                                            </td> 
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer   start-->

    <ModalsPart v-if="!['login'].includes($route.name)"  :userDetails="userDetails" @getNagarpalikaData="updateNagarpalikaData"/>
</template>

<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import axios from 'axios';
const config = {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: 'json'
         };
export default {
    name: 'Nagarpalika', 
    components: {
        PageTitle, ModalsPart
    }, mounted() {
        this.getBasics();

    }, data() {
        return {
          nagarpalikas: [],
            userDetails:[]
        }
    },
    methods: { 
               getImageUrl(logo) {
                  return `${this.$hostname}/writable/uploads/logos/${logo}`;
                },
        updateNagarpalikaData(value) {
            this.nagarpalikas = value;
        },
        getBasics() {
            var self = this;
            axios.post('getNagarpalikas').then(function (response) {
                self.nagarpalikas = response.data.nagarpalikas; 
                self.userDetails = response.data.userDetails; 
                window.scrollTo(0, 0);
            });
        },
        remove_nagarpalika(id) {
            var self = this;
            axios.post('removeNagarpalika', {id:id}).then(function (response) {
                self.$swal(response.data.msg);
                
                if(response.data.is==1)
                    self.nagarpalikas = response.data.nagarpalikas; 
                window.scrollTo(0, 0);
            });
        }
       
    }
}
</script>
