<template>

<div class="container-fluid">
      <div class="row">
        <div class="col-xl-7 order-1"><img class="bg-img-cover bg-center" src="../../assets/images/login/1.jpg" alt="looginpage"></div>
        <div class="col-xl-5 p-0">
          <div class="login-card login-dark">
            <div>
              <div><a class="logo text-start"><img class="img-fluid for-dark" src="../../assets/images/logo/logo_new.png" alt="looginpage">
                <img class="img-fluid for-light" src="../../assets/images/logo/logo_new.png" alt="looginpage"></a></div>
              <div class="login-main"> 
                <form @submit.prevent="login"  class="theme-form">
                  <h4>Sign in to account</h4>
                  <p>Enter your Username & password to login</p>
                  <div class="form-group">
                    <label class="col-form-label">Username</label>
                    <input type="text" class="form-control" id="username" v-model="username" required>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input type="password" class="form-control" id="password" v-model="password" required> 
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    
                    <div class="text-end mt-3">
                      <button type="submit" class="btn btn-primary">Login</button>
                    </div>
                  </div>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div> 
  </template>
  <style scooped>
      @media only screen and (max-width: 575.98px) {
    .login-card {
        padding-top: 40px;
        padding-bottom: 40px;
        background: #fbfbfb !important;
    }
}
  </style>
  <script>
  import PageTitle from '@/components/PageTitle.vue';
  import axios from 'axios';
  const config = {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: 'json'
         };
  export default {
    name: 'Login', 
    components: {
        PageTitle
    },
    mounted(){
        this.islogin();
    },
    data() {
      return {
        username: '',
        password: '',
        isAuthenticated: false,
        empRow:[]
      };
    },
    methods: {
      async login() {
        try {
          const response = await axios.post('verifyEmp', {
            empcode: this.username,
            pwd: this.password
          });
          // console.log(response.data);
          if (response.data.is === 1) {
              localStorage['token']=response.data.token;
              self.empRow=response.data.empRow;
              if(self.empRow.emprole==4)
                    window.location.replace(window.location.origin+"/frontdash");
              else
                    window.location.replace(window.location.origin+"/dashboard");
            //  this.$router.push('/home'); 
             }else
             this.$swal(response.data.msg);
                        
        } catch (error) {
          console.error('Login error:', error);
        }
      },
            async islogin() {
         
          const response = await axios.post('is_authenticated', { token:localStorage['token'] });
          // console.log(response.data);
          if (response.data.is === 1) {
              self.empRow=response.data.user;
              if(self.empRow.emprole==4)
                    window.location.replace(window.location.origin+"/frontdash");
              else
                    window.location.replace(window.location.origin+"/dashboard");
            //  this.$router.push('/home'); 
             }
             
                        
        
      }
    }
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  