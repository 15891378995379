<template>
     <div class="container-fluid">
            <div class="page-title">
              <div class="row">
                <div class="col-6">
                  <h4>{{title}}</h4>
                </div>
                <div class="col-6"> 
                  <ol class="breadcrumb">
                    
                    <router-link :to="path?path:'/'" class="breadcrumb-item">Dashboard</router-link>
                    <li to="/form" class="breadcrumb-item active">{{title}}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
</template>

<script scoped> 

export default {
  name: 'PageTitle',
  props: {
    title: String,
    path: String
  }
  
}
</script>
 
