<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Survey List" />
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                      
                        <div class="card-body badge-spacing">

                            <div class="table-responsive custom-scrollbar">
                                <table class="table display datatable" id="basic-1">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th  v-for="(item ,index)  in survey_ques" :key="item.id">{{item.que}}</th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  v-for="(item ,index)  in survey_report" :key="item.id" >
                                          <td>{{index+1}}</td>
                                            <td  v-for="(itemx ,indexx)  in item" :key="item.id" >{{itemx.answer}}</td>
                                          
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer   start-->
 
</template>

<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import axios from 'axios';
const config = {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: 'json'
         };
export default {
    name: 'SurveyReport', 
    components: {
        PageTitle, ModalsPart
    }, mounted() {
           if (this.$route.query.q) {
                this.getSurveyReport(this.$route.query.q);
            }else {
                this.getBasics();
            }

    }, data() {
        return {
          survey_report: [],
          survey_ques: [],
          fromDate: '',
          toDate: ''
        }
    },
    methods: { 
         
        getSurveyReport(servey_id) {
              var self = this;
            axios.post('getSurveyReport',{servey_id:servey_id}).then(function (response) {
                self.survey_ques  = response.data.survey_ques ;   
                self.userDetails  = response.data.userDetails ;   
                self.survey_report  = response.data.survey_report ;   
                window.scrollTo(0, 0);
            }).then(()=>{
                          $(".datatable").DataTable({
                           dom: 'Bfrtip',
                           buttons: [  'excel', 'pdf'],
                            "paging": true,
                            "ordering": true,  
                            "autoWidth": false });
        });
    },
    view_product(id)
    {
        var self = this;
        window.open(window.location.origin+'/productlistdetails?q='+id,'_blank');
    }
    }
}
</script>
