<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Question"  />
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-secondary" type="button" data-bs-toggle="modal"
                                data-original-title="test" data-bs-target="#questionAdd">Add Question</button>
                        </div>
                        <div class="card-body badge-spacing">
                            <div class="table-responsive custom-scrollbar">
                                <table class="table display" id="basic-1">
                                    <thead>
                                        <tr> 
                                            <th>Sr.No.</th> 
                                            <th>Survey</th> 
                                            <th>Question</th>
                                            <th>Answer Type</th>
                                            <th>Marks</th>
                                            <th>Action</th> 
                                
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  v-for="(item ,index)  in questions" :key="item.id" >
                                            <td>{{index+1}}</td>
                                            <td>{{item.survey_name}}</td>
                                            <td>{{item.que}}</td> 
                                            <td>{{ans_type[item.questiontype]}}</td> 
                                            <td>{{item.marks}}</td>
                                            <td>
                                                <ul class="action">
                                                    <li class="edit" @click="edit_question(item.id)"><i class="icon-pencil-alt"></i></li>
                                                    <li class="delete" @click="remove_question(item.id)"><i class="icon-trash"></i></li>
                                                </ul>
                                            </td> 
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer   start-->

    <ModalsPart v-if="!['login'].includes($route.name)"  :userDetails="userDetails" :survey_list="survey_list" @getQuestionData="updateQuestionData"/>
</template>

<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import axios from 'axios';
const config = {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: 'json'
         };
export default {
    name: 'Question', 
    components: {
        PageTitle, ModalsPart
    }, mounted() {
        this.getBasics();

    }, data() {
        return {
            ans_type:["","Text","Yes/No","Image Upload","Range","Location"],
            questions: [],
            survey_list:[],
            userDetails:[]
        }
    },
    methods: { 
        updateQuestionData(value) {
            this.questions = value;
        },
        getBasics() {
            var self = this;
            axios.post('getQuestions').then(function (response) {
                self.questions = response.data.questions; 
                self.survey_list = response.data.survey; 
                self.userDetails = response.data.userDetails; 
                window.scrollTo(0, 0);
            });
        },
        remove_question(id) {
            var self = this;
            axios.post('removeQuestion', {id:id}).then(function (response) {
                 self.$swal(response.data.msg);
                
                if(response.data.is==1){
                self.questions = response.data.questions; 
                self.survey_list = response.data.survey; 
                }
                window.scrollTo(0, 0);
            });
        }
       
    }
}
</script>
