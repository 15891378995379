<template>

  <!-- Page Sidebar Ends-->
  <div class="page-body">
  
    <PageTitle title="Dashboard" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row size-column">

        <div class="col-xxl-9 box-col-12">
          <div class="row">
            <div class="col-xl-3 col-sm-4" v-if="userDetails.emprole==999">
              <!-- <a href="#" class="card-link"> -->
                <router-link to="/nagarpalika" class="card-link">
              <div class="card o-hidden small-widget bg-success">
                <div class="card-body total-project border-b-primary border-2"><span class="f-light f-w-500 f-14 text-white">Nagarpalika</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-600 text-white">{{nagarpalika_count}}</h2> 
                    </div>
                    <div class="product-sub bg-primary-light">
                      <i class="fa fa-home"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
            </router-link>
              <!-- </a> -->
            </div>
           <!-- <div class="col-xl-3 col-sm-4" v-if="userDetails.emprole==999">
              <router-link to="/survey" class="card-link">
              <div class="card o-hidden small-widget bg-warning">
                <div class="card-body total-Progress border-b-dark border-2"> <span class="f-light f-w-500 f-14 text-white">Survey</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-600 text-dark">{{surveys_count}}</h2> 
                    </div>
                    <div class="product-sub bg-primary-light">
                      <i class="fa fa-list"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
            </router-link>
            </div>-->
            <div class="col-xl-3 col-sm-4">
              <router-link to="/users" class="card-link">
              <div class="card o-hidden small-widget bg-dark">
                <div class="card-body total-Complete border-b-warning border-2"><span
                    class="f-light f-w-500 f-144 text-white">Users</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-6004 text-white">{{users_count}}</h2> 
                    </div>
                    <div class="product-sub bg-secondary-light">
                       <i class="fa fa-users"></i>
                    </div>
                  </div>
                 
                </div>
              </div>
              </router-link>
            </div>
            <div class="col-xl-3 col-sm-4">
                <router-link to="/treereport" class="card-link">
              <div class="card o-hidden small-widget bg-info">
                <div class="card-body total-project border-b-primary border-2"><span class="f-light f-w-500 f-14 text-white">Tree Survey</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-600 text-white">{{tree_count}}</h2> 
                    </div>
                    <div class="product-sub bg-primary-light">
                        <i class="fa fa-tree"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
            </router-link>
            </div>
            
            <div class=" row"  >
                   <div class="card">
                          <div class="card-body">
                             <div class="col-xl-12 col-sm-12">
                                <Bar :data="data" :options="options" />
                                
                             </div>
                        </div>
                    </div>
                    
                     <div class="card">
                          <div class="card-body">
                               <div class=" row">
                                 <!--<div class="col-xl-4 col-12 col-md-4">-->
                                 <!--    <h6>Tree Conditions</h6>-->
                                 <!-- <Pie :data="piedata" :options="options" />-->
                                 <!--</div>-->
                                 
                                 <!--<div class="col-xl-4 col-12 col-md-4">-->
                                 <!--    <h6>Tree Ownership</h6>-->
                                 <!-- <Pie :data="piedata" :options="options" />-->
                                 <!--</div> -->
                                 </div> 
                           </div>
                  </div>
             </div>
          </div>
      </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>



</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,ArcElement
} from 'chart.js'
import { Bar,Pie } from 'vue-chartjs'

ChartJS.register(CategoryScale,ArcElement, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  name: 'HomeView',
  components: {
        PageTitle ,Bar,Pie
    },
    mounted() {
        this.getBasics();

    }, data() {
        return {
          nagarpalika_count: 0,
          surveys_count: 0,
          users_count: 0,
          damageCount: 0,
          x_values: [],
          purchaseStock: [],
          userDetails: {'emprole':0},
          saleStock: [],
          outwardCount: 0,
          tree_count:0,
          data: {
                labels: this.x_values,
                datasets: [{ data: this.purchaseStock},{data: this.saleStock }]
              },
              
            piedata : {
                  labels: ['Healthy', 'Average', 'Dead'],
                  datasets: [
                    {
                      backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
                      data: [10, 40,21]
                    }
                  ]
                },
          options: {
  maintainAspectRatio: false,
                responsive: true
              }
        }
    },
    methods: {
      getBasics() {
            var self = this;
            axios.post('getDashboard').then(function (response) {
                self.nagarpalika_count  = response.data.nagarpalika_count ; 
                self.surveys_count  = response.data.surveys_count ;
                self.tree_count  = response.data.tree_count ;
                self.users_count  = response.data.users_count ; 
                self.damageCount  = response.data.damage_count ; 
                self.x_values  = response.data.x_values ; 
                self.purchaseStock  = response.data.purchaseStock ; 
                self.saleStock  = response.data.saleStock ; 
                self.userDetails  = response.data.userDetails ; 
                
                self.data= {labels: self.x_values,datasets: response.data.dataset };//[{  backgroundColor: 'green', label: 'Inward Stock',data: self.purchaseStock},{ backgroundColor: '#f87979', label: 'Out-ward Stock',data: self.saleStock }]};
                
                 self.piedata.datasets.data= [response.data.Healthy,response.data.Average,response.data.Dead];
                
                window.scrollTo(0, 0);
            });
        }
    }
}
</script>
