<template>
    <button class="btn bt-danger" @click="backBtnPress()"><i class="fa fa-arrow-left"></i> Go Back</button>
   <div class="container-fluid">
    <div ref="map" style="width: 100%; height: 450px;"></div>
    <p>Selected Location: {{ latitude }}, {{ longitude }}</p>
    <div class="row">
         <button  class="btn btn-danger col-4"  @click="backBtnPress()"><i class="fa fa-arrow-left"></i> Go Back</button>   
         <div class="col-4"></div>
         <button  class="btn btn-primary col-4"  @click="captureLocation"><i class="fa fa-check"></i> Confirm</button>
    </div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
     props: {
         last_pins: {
                type: Array
            } 
  },
  data() {
    return {
      map: null,
      marker: null,
      latitude:0,
      longitude:0,
      isLocationAcepted:false, 
      selectedLocation: {
        lat: null,
        lng: null,
      },
      
    };
  },
 
  mounted() {
    const loader = new Loader({
      apiKey: 'AIzaSyD9gtJ2oDY7AcqUPTjmER1Ki0ZYC4mbIe0',
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then(() => {
      this.getLocation();
    });
    
    
  },
  methods: {
       backBtnPress() { 
              this.$emit('backBtnLoc');
            },
       refreshLoc() { 
              this.getLocation();
            },
            
       captureLocation() { 
              this.selectedLocation.lat=this.latitude;
              this.selectedLocation.lng=this.longitude;
              
              
              this.$emit('setLocation', this.selectedLocation);
            },
           getLocation(){
                navigator.geolocation.getCurrentPosition(this.success, this.error,{
                enableHighAccuracy: true, // Request higher accuracy
                timeout: 5000, // Timeout in milliseconds
                maximumAge: 0 // Don't use cached position
              }); 
            },
        
        error(err) {
           this.latitude=17.6599;
            this.longitude=75.9064;
             
             
             if(!this.isLocationAcepted){
                 this.isLocationAcepted=true;
                 this.getLocation();
                 this.initMap();
             }
        }, 
        success(position) {
            this.latitude  = position.coords.latitude;
            this.longitude = position.coords.longitude; 
            this.isLocationAcepted=true;
            this.initMap();
        },
    initMap() {
        var self=this;
          this.map = new google.maps.Map(this.$refs.map, {
                center: { lat:self.latitude, lng: self.longitude },
                zoom: 19,
                mapTypeId: google.maps.MapTypeId.SATELLITE
          });
       
   
      
      this.placeMarker(new google.maps.LatLng(self.latitude, self.longitude));

      this.map.addListener('click', (event) => {
        this.placeMarker(event.latLng);
      });
     
     google.maps.event.addListener(self.marker, 'dragend', function(event) {
          self.latitude  = event.latLng.lat();
          self.longitude = event.latLng.lng();  
         self.placeMarker(event.latLng);
      });
      
      
                 
      if(self.last_pins){
           //  alert(self.last_pins);
              for (var i = 0; i < self.last_pins.length; i++) {
                   var record= self.last_pins[i];
                 //  alert(record.tree_location_lng);
                   if(record.tree_location_lat && record.tree_location_lng)
                    self.placePreviousMarker(new google.maps.LatLng(record.tree_location_lat,record.tree_location_lng));
                 
              }
            
      }
      
    },
    placeMarker(location) {
      if (this.marker) {
        this.marker.setPosition(location);
      } else {
        this.marker = new google.maps.Marker({
          position: location,
          map: this.map,
         draggable: true
        });
      }
 
    },
    placePreviousMarker(location) {
       new google.maps.Marker({
          position: location,
          map: this.map
        });
      
    },
  },
};
</script>

<style>
/* Add any additional styles here */
</style>
