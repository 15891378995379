<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Offline Report"  path="frontdash"/>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                      
                        <div class="card-body badge-spacing">

                            <div class="table-responsive custom-scrollbar">
                                    <button class="btn btn-primary mb-3" v-show="isOnline" @click="syncData()"> Sync Data</button>
                                <table class="table display datatable" id="basic-1">
                                    <thead>
                                        <tr>
                                                <th>Sr.No</th>
                                             	<th>Survey Date</th> 
                                                <th>local_name</th>
                                                <th>comman_name</th>
                                                <th>botnical_name</th>
                                                <th>other_name</th>
                                                <th>tree lattitude</th> 
                                                <th>tree longitude</th> 
                                                <th>girth</th>
                                                <th>height</th>
                                                <th>Conopy diameter</th>
                                                <th>tree_condition</th>
                                                <th>tree_balance</th>
                                                <th>remark</th>
                                                 <!--<th>other_remark</th>
                                                <th>dist_from_prev</th>-->
                                                <th>ownership</th>
                                               <!-- <th>spacial_comment</th>-->
                                                <th>society_name</th>
                                                <th>road_name</th>
                                                <!-- <th>bird_nest</th>-->
                                                <th class="hide">ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  v-for="(item ,index)  in treereports" :key="item.id" >
                                             <th>{{index+1}}</th>
                                             <th>{{item.iddate}}</th> 
                                                <td>{{item.local_name}}</td>
                                                <td>{{item.comman_name}}</td>
                                                <td>{{item.botnical_name}}</td>
                                                <td>{{item.other_name}}</td>
                                                <td>{{item.tree_location_lat}}</td> 
                                                <td>{{item.tree_location_lng}}</td> 
                                                <td>{{item.girth}}</td>
                                                <td>{{item.height}}</td>
                                                <td>{{item.diameter}}</td>
                                                <td>{{item.tree_condition}}</td>
                                                <td>{{item.tree_balance}}</td>
                                                <td>{{item.remark}}</td>
                                                <!-- <td>{{item.other_remark}}</td>
                                                <td>{{item.dist_from_prev}}</td>-->
                                                <td>{{item.ownership}}</td>
                                                <!-- <td>{{item.spacial_comment}}</td>-->
                                                <td>{{item.society_name}}</td>
                                                <td>{{item.road_name}}</td>
                                                <!-- <td>{{item.bird_nest}}</td>-->
                                                <td class="hide">{{item.id}}</td>
                                                </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer   start-->
 
</template>
<style scopped>
    th{
            text-transform: capitalize;
    }
    .table th, .table td{
        padding: 0.4em !important;
    }
    .hide{
        display: none;
    }
</style>
<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue'; 
import axios from 'axios';
import { getAllSurveys,clearAllSurveyData} from '@/dexie';
 
export default {
    name: 'OfflineReport', 
    components: {
        PageTitle,getAllSurveys,clearAllSurveyData
    }, mounted() {
         if (this.$route.query.q) {
                this.getBasics(1);
            }else {
               this.getBasics(0);
            }
        

    }, data() {
        return {
          treereports: [],
          fromDate: '',
          toDate: '',
          userDetails:{"emprole":0},
          datatable:null,
           isOnline:navigator.onLine, // Initial status
        }
    },
     beforeUnmount() {
    // Clean up event listeners
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
    methods: { 
         updateOnlineStatus() {
          this.isOnline = navigator.onLine;
        },
       
        async getBasics(today) {
          
             const data_here= await getAllSurveys();
               if(data_here.length>0){
                    //self.treenames  = JSON.parse(data_here[0].data);
                    var temp_array=[];
                    for (var i = data_here.length - 1; i >= 0; i--) {
                        temp_array.push(JSON.parse(data_here[i].data));
                    }
                    this.treereports=temp_array;//JSON.parse(data_here[0].data);  
               } 
        },
       async syncData(){
           $(".loader-wrapper").show(); 
           var self=this;
              const data_here= await getAllSurveys();
               if(data_here.length>0){
                    //self.treenames  = JSON.parse(data_here[0].data);
                    var temp_array=[];
                    for (var i = 0; i < data_here.length; i++) {
                        
                        
                           // const blob = self.dataURLtoBlob(this.tree.image);
                                var cur_id=    data_here[i].id;
                                 var ques=data_here[i].data;
                                const formData = new FormData();
                                formData.append('image', data_here[i].blob, 'captured_photo.png'); 
                                formData.append('ques', ques); 
                                formData.append('syncid', cur_id); 
                                
                                
                                axios.post('syncTreeSurvey',formData).then(function (response) {
                               
                                if(response.data.status==1){
                                   // alert(response.data.syncid);
                                    deleteSurveyRecord(response.data.syncid);
                                }
                              // self.$swal(response.data.msg);
                             //  self.surveys  = response.data.surveys ;   
                              
                               
                              //  window.scrollTo(0, 0);
                            });
                            
                        
                        temp_array.push(JSON.parse(data_here[i].data));
                    }
                    this.treereports=temp_array;//JSON.parse(data_here[0].data);  
               }
               
               
                 $(".loader-wrapper").hide();
        }
         
    }
}
</script>
