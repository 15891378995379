<template>

  <!-- Page Sidebar Ends-->
  <div class="page-body">
  
    <PageTitle title="Surveys List" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row size-column">

        <div class="col-xxl-9 box-col-12">
          <div class="row">
           
            <div class="col-xl-3 col-sm-6"  v-for="(item ,index)  in surveys" :key="item.id"  >
             
              <div class="card o-hidden small-widget" @click="gerSurveyReport(item.id)">
                <div class="card-body total-Progress border-b-warning border-2"> <span class="f-light f-w-500 f-14">{{item.name}}</span>
                  <div class="project-details">
                    <div class="project-counter">
                      <h2 class="f-w-600">{{item.survey_count}}</h2> 
                    </div>
                    <div class="product-sub bg-warning-light">
                      
                    </div>
                  </div>
                  
                </div>
              </div>
          
            </div>
          
            </div>
             
          </div>
      </div>
      </div>
    </div>
    <!-- Container-fluid Ends--> 

</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';
 
export default {
  name: 'SurveyCats',
  components: {
        PageTitle  
    },
    mounted() {
        this.getBasics();

    }, data() {
        return {
          nagarpalika_count: 0,
          surveys_count: 0,
          surveys: [],
          entry_dates: [],
          purchaseStock: [],
          saleStock: [],
          data: {
                labels: this.entry_dates,
                datasets: [{ data: this.purchaseStock},{data: this.saleStock }]
              },
          options: {
  maintainAspectRatio: false,
                responsive: true
              }
        }
    },
    methods: {
      getBasics() {
            var self = this;
            axios.post('getFrontDash').then(function (response) {
                self.surveys  = response.data.surveys ;   
                window.scrollTo(0, 0);
            });
        },
        gerSurveyReport(item_id){
             this.$router.push({ name: "surveyreport" , query: { q: item_id} }); 
        }
    }
}
</script>
