<template>
     
      <WebcamCapture />
     
  </template>
  
  <script>
  import WebcamCapture from '@/components/WebcamCapture.vue';
  
  export default {
    components: {
      WebcamCapture,
    },
  };
  </script>
  
  <style scoped>
  #app {
    text-align: center;
    margin-top: 50px;
  }
  </style>
  